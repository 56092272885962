<template>
  <div class="container homepage-containers">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <router-link
          to="/course-book/admin"
          class="homepage-container admin d-flex flex-column align-items-center text-center"
        >
          <div class="icon mb-4">
            <img src="/img/icons/Icon_book.png" alt="" />
          </div>
          <h4>Podręcznik</h4>
          <p>
            Pomoże Ci rozpocząć pracę z systemem oraz krok po kroku poznawać
            wszystkie jego możliwości
          </p>
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <a
          href="https://jira.hrtec.pl/servicedesk"
          target="_blank"
          class="homepage-container admin d-flex flex-column align-items-center text-center"
        >
          <div class="icon mb-4">
            <img src="/img/icons/Icons_report.png" alt="" />
          </div>
          <h4>Zgłoszenia</h4>
          <p>
            Masz pomysł na nową funkcjonalność? Znalazłeś błąd? Tutaj możesz
            zgłosić nam każdy problem lub podzielić się swoją opinią
          </p>
        </a>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <router-link
          to="/documentation"
          class="homepage-container admin d-flex flex-column align-items-center text-center"
        >
          <div class="icon mb-4">
            <img src="/img/icons/Icon_documentation.png" alt="" />
          </div>
          <h4>Dokumentacja</h4>
          <p>
            Tu pobierzesz wszystkie instrukcje, dokumenty i inne materiały,
            które dla Ciebie przygotowujemy.
          </p>
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <router-link
          to="/download"
          class="homepage-container admin d-flex flex-column align-items-center text-center"
        >
          <div class="icon mb-4">
            <img src="/img/icons/Icon_download.png" alt="" />
          </div>
          <h4>Do pobrania</h4>
          <p>
            Tu znajdziesz praktyczne grafiki do wykorzystania. Tła pulpitu,
            prezentacji, naklejki dla pracowników
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminPageContainers",
};
</script>
