var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"m-0 p-0 flex-column"},_vm._l((_vm.posts),function(post){return _c('li',{key:post.ID,class:{
      'list-open': post.open,
      'tree-main-parent': post.menu_order === 1,
    },attrs:{"data-id":post.ID}},[_c('div',{staticClass:"post-tree-name cursor-pointer",class:{
        'font-weight-bold': post.open,
      }},[_c('div',{ref:"mainParents",refInFor:true,staticClass:"tree-title",class:{
          'has-child': post.childs && post.childs.length,
        },attrs:{"id":`clickID_${post.ID}`,"data-id":post.ID},on:{"click":function($event){return _vm.openPost(post)}}},[_vm._v(" "+_vm._s(post.post_title)+" ")])]),(post.childs && post.childs.length)?_c('CourseBookTree',{staticClass:"ml-5",class:{ 'd-flex': post.open, 'd-none': !post.open },attrs:{"posts":post.childs},on:{"post":_vm.updatePost,"foundPost":_vm.foundPost}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }