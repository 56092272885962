<template>
  <ul class="m-0 p-0 flex-column">
    <li
      :data-id="post.ID"
      :class="{
        'list-open': post.open,
        'tree-main-parent': post.menu_order === 1,
      }"
      :key="post.ID"
      v-for="post in posts"
    >
      <div
        class="post-tree-name cursor-pointer"
        :class="{
          'font-weight-bold': post.open,
        }"
      >
        <div
          @click="openPost(post)"
          class="tree-title"
          :id="`clickID_${post.ID}`"
          ref="mainParents"
          :data-id="post.ID"
          :class="{
            'has-child': post.childs && post.childs.length,
          }"
        >
          {{ post.post_title }}
        </div>
      </div>

      <CourseBookTree
        class="ml-5"
        @post="updatePost"
        v-if="post.childs && post.childs.length"
        :class="{ 'd-flex': post.open, 'd-none': !post.open }"
        :posts="post.childs"
        @foundPost="foundPost"
      />
    </li>
  </ul>
</template>

<script>
import forEach from "lodash.foreach";
import { find } from "lodash";

export default {
  props: {
    posts: {
      type: Array,
    },
  },
  name: "CourseBookTree",
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query && this.$route.query.id) {
        const foundPost = find(this.posts, {
          ID: parseInt(this.$route.query.id),
        });
        if (foundPost) {
          this.$emit("foundPost", {
            post: foundPost,
            originPostID: this.$route.query.id,
            originPost: foundPost,
            post_parentID: foundPost.post_parent,
          });
          foundPost.open = true;
        }
      }
    });
  },
  methods: {
    foundPost(data) {
      const foundPost = find(this.posts, { ID: parseInt(data.post_parentID) });
      if (foundPost) {
        this.$emit("foundPost", {
          post: foundPost,
          originPostID: this.$route.query.id,
          originPost: data.originPost,
          post_parentID: foundPost.post_parent,
        });
        foundPost.open = true;
      }
    },
    hidePosts(posts) {
      forEach(posts, (post) => {
        post.open = false;
        if (post.childs && post.childs.length) {
          this.hidePosts(post.childs);
        }
      });
    },
    updatePost(data) {
      this.$emit("post", data);
    },
    openPost(post) {
      this.hidePosts(this.posts);
      if (post.childs && post.childs.length) {
        post.open = !post.open;
      }
      this.updatePost(post);
    },
  },
};
</script>
<style lang="scss">
@import "src/assets/scss/colors";

li {
  &.list-open {
    position: relative;
    color: $blue;
    &:after {
      content: "";
      height: calc(100% - 40px);
      width: 1px;
      border-left: 1px dashed $purple;
      position: absolute;
      z-index: 10;
      top: 30px;
      margin: auto;
      left: 10px;
    }
  }
}
.tree-title {
  font-weight: 600;
  font-size: 18px;
  &.has-child {
    font-weight: 800;
  }
  &:hover {
    font-weight: 800;
    color: $purple;
  }
}
</style>
